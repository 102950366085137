var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm._.size(_vm.filteredItems) <= 0)?_c('div',{staticClass:"text--secondary"},[_vm._v("Пока нет ни одного элемента.")]):_c('v-treeview',{attrs:{"items":_vm.filteredItems,"load-children":_vm.fetch,"active":_vm.selected,"open":_vm.opened,"dense":"","item-key":"id","item-text":"courseName","color":"purple darken-3","activatable":""},on:{"update:active":[function($event){_vm.selected=$event},_vm.select],"update:open":function($event){_vm.opened=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.courseTypeId === 0 && item.isMerge)?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-merge")]):_vm._e(),(!item.sync)?_c('v-icon',[_vm._v("mdi-cloud-off-outline")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"wrap"},domProps:{"innerHTML":_vm._s(item.courseName)}}),_c('div',{staticClass:"text-caption text--secondary fs-12 lh-12 mb-1"},[_vm._v(" "+_vm._s(_vm._.get(_vm.$const.eduCourse.types, _vm._.get(item, 'courseTypeId', 0)))+" "),(item.courseTypeId === 0)?_c('span',[_vm._v(", создан "+_vm._s(item.created_at_date))]):_vm._e()])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.allowEdit)?_c('v-menu',{staticStyle:{"max-width":"600px"},attrs:{"absolute":"","offset-y":"","close-on-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.courseTypeId < 3)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"purple darken-3"}},[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getTypes(item)),function(type){return _c('v-list-item',{key:type.value,on:{"click":function($event){return _vm.create(item, type)}}},[_c('v-list-item-title',[_vm._v(_vm._s(type.title))])],1)}),1)],1):_vm._e(),(_vm.allowEdit)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.remove($event, item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}])}),_c('v-dialog',{attrs:{"persistent":"","max-width":"480"},model:{value:(_vm.dialog.remove.show),callback:function ($$v) {_vm.$set(_vm.dialog.remove, "show", $$v)},expression:"dialog.remove.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Удаление элемента ")]),_c('v-card-text',[_vm._v(" Вы действительно хотите безвозратно удалить элемент и все его дочерние элементы? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"purple darken-3","disabled":_vm.dialog.remove.loading},on:{"click":function($event){return _vm.remove($event, false)}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"color":"purple darken-3","text":"","loading":_vm.dialog.remove.loading},on:{"click":function($event){return _vm.remove($event)}}},[_vm._v(" Да ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }