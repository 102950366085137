<template>
    <div>
        <div v-if="_.size(filteredItems) <= 0" class="text--secondary">Пока нет ни одного элемента.</div>
        <v-treeview
            v-else
            :items="filteredItems"
            :load-children="fetch"
            :active.sync="selected"
            :open.sync="opened"
            dense
            item-key="id"
            item-text="courseName"
            color="purple darken-3"
            activatable
            @update:active="select"
        >
            <template v-slot:prepend="{ item }">
                <v-icon v-if="item.courseTypeId === 0 && item.isMerge" class="mr-1">mdi-merge</v-icon>
                <v-icon v-if="!item.sync">mdi-cloud-off-outline</v-icon>
            </template>
            <template v-slot:label="{ item }">
                <div v-html="item.courseName" style="white-space: wrap;" />
                <div class="text-caption text--secondary fs-12 lh-12 mb-1">
                    {{ _.get($const.eduCourse.types, _.get(item, 'courseTypeId', 0)) }}
                    <span v-if="item.courseTypeId === 0">, создан {{ item.created_at_date }}</span>
                </div>
            </template>
            <template v-slot:append="{ item }">
                <v-menu
                    v-if="allowEdit"
                    absolute
                    offset-y
                    close-on-click
                    style="max-width: 600px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-if="item.courseTypeId < 3" 
                            icon 
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon color="purple darken-3">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item
                            v-for="type in getTypes(item)"
                            :key="type.value"
                            @click="create(item, type)"
                        >
                            <v-list-item-title>{{ type.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn v-if="allowEdit" icon @click.prevent.stop="remove($event, item.id)">
                    <v-icon color="red">mdi-delete-outline</v-icon>
                </v-btn>
            </template>
        </v-treeview>

        <!-- Remove confirm dialog -->
        <v-dialog v-model="dialog.remove.show" persistent max-width="480">
            <v-card>
                <v-card-title class="text-h5">
                    Удаление элемента
                </v-card-title>
                <v-card-text>
                    Вы действительно хотите безвозратно удалить элемент и все его дочерние элементы?
				</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="purple darken-3" :disabled="dialog.remove.loading" @click="remove($event, false)">
                        Отмена
                    </v-btn>
                    <v-btn color="purple darken-3" text :loading="dialog.remove.loading" @click="remove($event)">
                        Да
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END Remove confirm dialog -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";

const TREE_FIELDS = 'id,parent_id,created_at_date,version,externalId,externalParentId,lft,courseName,courseTypeId,sync';
export default {
    name: "DigitalEduCourseTree",
    props: {
        items: Array,
        hideMerged: { type: Boolean, default: false }
    },
    data() {
        return {
			dialog: {
				remove: {
					show: false,
					id: null,
                    loading: false
				}
			},
            selected: [],
            opened: [],
        };
    },
    computed: {
        ...mapGetters("user", ["allowEdit"]),
        // Если hideMerged === true , то отображаем только те ЦОК,
        // которые не были объеденины
        filteredItems () {
            if (!this.hideMerged) return this.items;
            let itemsToHide = [];
            this.items.map(item => {
                if (item.merge?.length) {
                    itemsToHide = itemsToHide.concat(item.merge);
                }
            })
            return this.items.filter(item => !itemsToHide.includes(item.externalId));
        }
    },
    methods: {
        async fetch(item) {
            await this.$store.dispatch('app/fetch', {
                filter: ['=', 'parent_id', item.id],
                fields: TREE_FIELDS
            });
        },
        async select(value) {
            if(_.size(value) <= 0)
                return ;
            
            const id = _.first(value);
            await this.$store.dispatch('app/toggleLoading', true);
            await this.$store.dispatch('app/fetch', {
                filter: ['=', 'id', id]
            });
            this.$store.dispatch("app/updateEduCourse", id);
            await this.$store.dispatch('app/toggleLoading', false);
        },
        reset() {
            this.selected = [];
        },
        create(parent, type = null) {
            this.$store.dispatch('app/updateEduCourse', {
                data: {
                    isNewRecord: true,
                    courseTypeId: _.isNil(type) ? parent.courseTypeId + 1 : type.value,
                    externalParentId: parent.externalId
                }
            })
        },
		async remove(e, value) {
			if(value === false)
			{
				this.dialog.remove = {
					show: false,
					id: null,
                    loading: false
				}
			}
			else if (this.dialog.remove.show !== true)
			{
				this.dialog.remove = {
					show: true,
					id: value,
                    loading: false
				}
			}
			else
			{
                this.dialog.remove.loading = true;
				await this.$store.dispatch("app/remove", this.dialog.remove.id);
                this.remove(null, false);
			}
		},
        getTypes(item) {
            return _.filter(_.map(this.$const.eduCourse.types, (v,k) => {
                return {
                    title: v,
                    value: k
                }
            }), o => {
                return o.value > item.courseTypeId;
            });
        }
    },
};
</script>
<style scoped lang="scss"></style>
