<template>
    <editor v-if="isAuthorized && (isAdmin || isViewer || isSupport)" />
    <home v-else />
</template>
<script>
import { mapGetters } from 'vuex'
import Home from './Home.vue'
import Editor from './admin/Editor.vue'
export default {
    name: 'Main',
    components: { Home, Editor },
    computed: {
        ...mapGetters('user', ['isAuthorized', 'isAdmin', 'isViewer', 'isSchool', 'isSupport'])
    },
    created () {
        if (this.isAuthorized && this.isSchool) {
            location = '/start';
        }
    }
}
</script>