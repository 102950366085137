<template>
    <v-form class="custom-form" @submit.prevent.stop="submit">
        <v-card elevation="2">

            <template v-if="fieldsVisible">
                <v-card-title
                    class="fw-700 d-flex flex-row justify-space-between align-center blue-gradient-4 mb-2"
                    :style="titleBorderRadius ? { borderRadius: titleBorderRadius } : null"
                >
                    <span>Форма обратной связи</span>
                </v-card-title>
                <!-- Body -->
                <v-card-text>
                    <v-text-field 
                        v-model="$v.form.surname.$model"
                        :error-messages="getErrors('form.surname')"
                        name="surname" 
                        type="text" 
                        label="Фамилия"
                        color="#2D9CDB"
                        clearable
                        clear-icon="mdi-close-circle"
                        flat
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field 
                        v-model="$v.form.name.$model"
                        :error-messages="getErrors('form.name')"
                        name="name" 
                        type="text" 
                        label="Имя"
                        color="#2D9CDB"
                        clearable
                        clear-icon="mdi-close-circle"
                        flat
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field 
                        v-model="$v.form.secondName.$model"
                        :error-messages="getErrors('form.secondName')"
                        name="secondName"
                        type="text" 
                        label="Отчество"
                        color="#2D9CDB"
                        clearable
                        clear-icon="mdi-close-circle"
                        flat
                    ></v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-text-field 
                        v-model="$v.form.email.$model"
                        :error-messages="getErrors('form.email')"
                        name="email" 
                        type="text" 
                        label="Адрес электронной почты"
                        color="#2D9CDB"
                        clearable
                        clear-icon="mdi-close-circle"
                        flat
                    ></v-text-field>
                </v-card-text>

                <div class="v-card__text special-selection__wrapper pos-rel mb-8 mt-2">
                    <v-select
                        v-model="$v.form.course_id.$model"
                        :items="eduCourses"
                        :error-messages="getErrors('form.course_id')"
                        outlined
                        label="Выберите ЦОК"
                        append-icon="mdi-chevron-down"
                        rows="6"
                        color="#2D9CDB"
                        class="special-selection"
                    ></v-select>

                    <div
                        class="special-selection__content pt-6"
                        :class="{expanded: courseFilterExpanded}"
                    >

                        <div class="d-flex justify-end align-center mt-5">
                            <span
                                class="text-blue c-pointer"
                                :class="{'mr-n4': !courseFilterExpanded}"
                                @click="courseFilterExpanded = !courseFilterExpanded"
                            >Расширенный поиск</span>
                            <span
                                v-if="courseFilterExpanded"
                                class="ml-10 text-red c-pointer"
                                @click="clearDecFilters"
                            >Сбросить</span>
                        </div>

                        <template v-if="courseFilterExpanded">
                            <v-text-field
                                v-model="courseSearch"
                                name="courseSearch"
                                type="text" 
                                label="Поиск"
                                color="#2D9CDB"
                                clearable
                                clear-icon="mdi-close-circle"
                                flat
                            ></v-text-field>

                            <div class="expansion-panel__subtitle d-flex align-center justify-space-between mb-3 mt-2">
                                <span>Какой класс вас интересует?</span>
                                <v-icon
                                    :color="getClearIconColor('classes')"
                                    class="mr-1"
                                    title="Очистить фильтр"
                                    @click="clearDecFilter('classes')"
                                >mdi-close-circle</v-icon>
                            </div>
                            <div
                                class="flex flex-wrap align-center"
                                style="max-width: 380px;"
                            >
                                <span
                                    v-for="item in classes"
                                    :key="item.value"
                                    class="checkbox mr-5 mt-5 flag-place"
                                    :class="{active: item.active}"
                                    @click="toggleDecFilter($event, item)"
                                >
                                    {{ item.value }}
                                </span>
                            </div>

                            <div class="hr mt-10"></div>

                            <div class="expansion-panel__subtitle d-flex align-center justify-space-between mb-8 mt-8">
                                <span>Какой предмет вы хотите выбрать?</span>
                                <v-icon
                                    :color="getClearIconColor('decTags')"
                                    class="mr-1"
                                    title="Очистить фильтр"
                                    @click="clearDecFilter('decTags')"
                                >mdi-close-circle</v-icon>
                            </div>

                            <div class="flex flex-wrap align-center">
                                <span
                                    v-for="item in decTags"
                                    :key="item.id"
                                    class="custom-checkbox flag-place"
                                    :class="{active: item.active}"
                                    @click="toggleDecFilter($event, item, 'right')"
                                >
                                    <span>{{ item.name }} <span v-if="item.decsCount" class="text-grey">({{item.decsCount}})</span></span>
                                    <ok-icon class="ok-icon" />
                                </span>
                            </div>

                            <flag-icon
                                v-if="flagPosition && typeof flagPosition === 'object'"
                                class="pos-abs c-pointer"
                                :style="{
                                    right: `${flagPosition.right}`,
                                    left: `${flagPosition.left}`,
                                    top: `${flagPosition.top}`,
                                    transform: 'translateY(-50%)'
                                }"
                                @click.native="hideFlag"
                            >
                                Найдено ({{ eduCourses.length }})
                            </flag-icon>
                        </template>
                    </div>
                </div>

                <v-card-text>
                    <v-select
                        v-model="$v.form.category_id.$model"
                        :items="categories"
                        :error-messages="getErrors('form.category_id')"
                        item-text="text"
                        item-value="id"
                        outlined
                        label="Выбрать поддержку"
                        append-icon="mdi-chevron-down"
                        rows="6"
                        color="#2D9CDB"
                    ></v-select>
                </v-card-text>
            
                <v-card-text>
                    <v-textarea
                        v-model="$v.form.message.$model"
                        :error-messages="getErrors('form.message')"
                        name="message" 
                        label="Ваше сообщение"
                        color="#2D9CDB"
                        solo
                        flat
                        outlined
                        clearable
                        clear-icon="mdi-close-circle"
                    ></v-textarea>
                </v-card-text>

                <v-card-text>
                    <v-file-input
                        v-model="form.file"
                        ref="fileInput"
                        accept=".zip,.rar,.doc,.docx,.xls,.xlsx,.pdf,.png,.jpeg,.jpg"
                        label="Загрузите файл"
                        color="#2D9CDB"
                        outlined
                        dense
                        class="d-none"
                    ></v-file-input>

                    <div
                        class="new-file-zone"
                        :class="{'not-empty': !!form.file}"
                        @click="showSystemFilePicker"
                    >
                        <img v-if="!form.file" src="@/assets/img/icon-plus.svg" />
                        <template v-else>
                            <span>{{ form.file.name }}</span>
                            <v-icon
                                color="error"
                                class="clear-icon"
                                @click.stop="removeFile"
                            >mdi-close-circle</v-icon>
                        </template>
                    </div>
                </v-card-text>
                
                <!-- Footer -->
                <v-card-actions class="d-flex justify-end">
                    <v-btn 
                        color="white" 
                        :elevation="0"
                        :loading="loading" 
                        type="submit"
                        class="btn__blue py-2 mt-4"
                        @click.prevent.stop="submit"
                    >
                        Отправить
                    </v-btn>
                </v-card-actions>
            </template>
            <v-card-text v-show="formErrors && formErrors.length" ref="errorMessages">
                <div class="server-error my-4">
                    <div class="ml-3 d-flex flex-column">
                        <span
                            v-for="(err, index) in formErrors"
                            :key="index"
                        >{{ err }}</span>
                    </div>
                    <img class="mr-2" src="@/assets/img/icon-error.svg">
                </div>
           </v-card-text>

           <v-card-text v-show="isSuccess">
                <div class="successMessage my-4 py-2 d-flex justify-space-between align-center">
                    <span class="ml-4">Обращение успешно отправлено</span>
                    <img src="@/assets/img/icon-ok.svg" class="mr-4">
                </div>
                <v-card-actions class="d-flex justify-center">
                    <v-btn 
                        color="white" 
                        :elevation="0"
                        :loading="loading" 
                        class="btn__white py-2 mt-4 mx-2"
                        @click.prevent.stop="() => $emit('close')"
                    >
                        Закрыть
                    </v-btn>
                    <v-btn 
                        color="white" 
                        :elevation="0"
                        :loading="loading" 
                        type="submit"
                        class="btn__blue py-2 mt-4 mx-2"
                        @click.prevent.stop="reloadForm"
                    >
                        Отправить ещё раз
                    </v-btn>
                </v-card-actions>
           </v-card-text>
        </v-card>
    </v-form>
</template>
<script>
import { vuelidateErrorMixin } from '@/helpers/VuelidateHelpers'
import ArrayHelper from '@/helpers/ArrayHelper'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/FormMixin'
import OkIcon from '@/components/icons/OkIcon.vue'
import FlagIcon from '@/components/icons/FlagIcon.vue'

const defaultClearIconColor = 'rgba(204, 204, 204, 1)';
const activeClearIconColor = '#2e9cdb';

export default {
    name: 'FeedbackForm',
    mixins: [ validationMixin, vuelidateErrorMixin, FormMixin ],
    components: { OkIcon, FlagIcon },
    props: {
        titleBorderRadius: { type: String, default: null },
        // По умолчанию включено автозаполнение полей данными из профиля
        disableAutoComplete: { type: Boolean, default: false}
    },
    data () {
        return {
            courseFilterExpanded: false,
            form: null,
            categories: [
                { id: 1, text: 'Техническая поддержка' },
                { id: 2, text: 'Методическая поддержка' }
            ],
            isSuccess: false,
            fieldsVisible: true,
            classes: [],
            decTags: [],
            flagPosition: null,
            courseSearch: '',
            localErrors: null,
            fieldNames: {
                name: 'Имя',
                secondName: 'Фамилия',
                surname: 'Отчество',
                email: 'Email',
                message: 'Сообщение',
                category_id: 'Тип поддержки',
                course_id: 'ЦОК',
            }
        }
    },
    validations: {
        form: {
            name: { required },
            secondName: { required },
            surname: { required },
            email: { required },
            message: { required },
            category_id: { required },
            course_id: { required },
        }
    },
    created () {
        this.formReset();
    },
    computed: {
        formErrors () {
            const errors = this.localErrors || this.serverErrors;
            return errors ? ArrayHelper.promiseArray(errors) : false;
        },
        externalId () {
            return window.top.location.href.match(/(dec|digital-content)\/(.*?)(\?|\r|\n|#|$|\/)/)?.[2] || null;
        },
        eduCourses () {
            const selectedGrades = this.classes.filter(c => c.active).map(c => c.value);
            const selectedDecTags = this.decTags.filter(t => t.active).map(t => t.id);

            let decs = this.$store.state.app.eduCoursesShort.map(c => {
                return {
                    text: c.courseName,
                    value: c.id,
                    externalId: c.data?.externalId,
                    grades: _.cloneDeep(c.data?.grades || []),
                    tags: _.cloneDeep(c.data?.tags.map(t => t.id) || [])
                }
            })
            // Filter by name
            if (this.courseSearch) {
                decs = decs.filter(dec => dec.text.includes(this.courseSearch))
            }
            // Filter by class grades
            if (selectedGrades.length) {
                decs = decs.filter(dec => {
                    let hasMatch = false;
                    selectedGrades.map(grade => {
                        if (dec.grades.includes(grade)) {
                            hasMatch = true;
                        }
                    })
                    return hasMatch;
                })
            }
            // Filter by discipline
            if (selectedDecTags.length) {
                decs = decs.filter(dec => {
                    let hasMatch = false;
                    selectedDecTags.map(tagId => {
                        if (dec.tags.includes(tagId)) {
                            hasMatch = true;
                        }
                    })
                    return hasMatch;
                })
            }
            return decs;
        }
    },
    async mounted () {
        await this.$store.dispatch('app/fetchFast', {filter: ['AND', ['=', 'courseTypeId', 0], ['=', 'synced', 1], ['=', 'enabled', 1]]});
        this.classes = new Array(11).fill(null).map((item, index) => ({ value: index + 1, active: false }));
        
        if (!this.$store.state.app.eduCoursesTags?.length) {
            await this.$store.dispatch("app/fetchTags");
        }
        this.fillDecTags();

        if (!this.disableAutoComplete) {
            this.autoCompleteFromState();
        }
    },
    methods: {
        autoCompleteFromState () {
            const profile = this.$store.state.user.profile;

            if (!profile && !this.externalId) {
                console.log('Not enaugh data to autocomplete form from store.');
                return false;
            }

            if (profile?.surname) this.form.surname = profile.surname;
            if (profile?.name) this.form.name = profile.name;
            if (profile?.middleName) this.form.secondName = profile.middleName;
            if (profile?.email) this.form.email = profile.email;
            if (this.externalId) this.form.course_id = this.$store.state.app.eduCoursesShort?.find(c => c.data?.externalId === this.externalId)?.id || null;
        },
        hideFlag () {
            this.flagPosition = null;
        },
        moveElemOnFlatPlace (event, side = 'left') {
            if (!Array.isArray(event?.path)) { return false; }
            
            let flagPlace = null;

            for (const key in event.path) {
               const htmlElement = event.path[key];
               const hasClass = htmlElement?.classList?.contains('flag-place');
               if (hasClass) {
                flagPlace = htmlElement;
                break;
               }
            }

            if (flagPlace) {
                this.flagPosition = {
                    top: (flagPlace.offsetTop + flagPlace.offsetHeight / 2) + 'px',
                    left: side === 'left' ? (flagPlace.offsetLeft + flagPlace.offsetWidth) + 'px' : 'initial',
                    right: side === 'right' ? '10px' : 'initial',
                }
            }
        },
        getClearIconColor (field) {
            if (!this[field]) { return defaultClearIconColor }
            // Если при проверке на наличие активных элементов возвращено значение undefined или 0,
            // то возвращаем дефолтный цвет. Иначе цвет активности
            return ![undefined, 0].includes(this[field]?.filter(item => item.active)?.length) ? activeClearIconColor : defaultClearIconColor;
        },
        clearDecFilters () {
            ['courseSearch', 'decTags', 'classes'].map(field => {
                this.clearDecFilter(field)
            })
        },
        clearDecFilter (field) {
            if (!this[field] === undefined) { throw new Error('Unknown field name'); }
            if (Array.isArray(this[field])) {
                this[field] = this[field].map(item => {
                    item.active = false;
                    return item;
                });
            } else if (typeof this[field] === 'string') {
                this[field] = ''
            }
            this.form.course_id = null;
            this.flagPosition = null;
        },
        toggleDecFilter (event, item, side = 'left') {
            this.moveElemOnFlatPlace(event, side);
            item.active = !item.active;
            this.form.course_id = null;
        },
        fillDecTags () {
            this.decTags = this.$store.state.app.eduCoursesTags?.
                            filter(tag => tag.parent_id === 'A5')?.
                            map(tag => {
                                return {
                                    id: tag.id,
                                    name: tag.name,
                                    active: false,
                                    decsCount: this.$store.state.app.eduCoursesShort.filter(dec => dec.data.tags.map(t => t.id).includes(tag.id))?.length
                                }
                            })
        },
        async submit () {
            this.loading = true;
            this.isSuccess = false;
            this.serverErrors = null;
            this.localErrors = null;
                if (this.validate()) {
                    try {
                        let url = null;
                        // Загружаем файл
                        if (this.form.file) {
                            const { data, error } = await this.$store.dispatch('support/uploadDocument', { file: this.form.file })
                            if (error) {
                                this.showError(error);
                                return;
                            }
                            url = data.url;
                        }
                        // Загружаем остальные данные
                        const form = {...this.form};
                        if (url) { form.file = url; }
                        if (form.course_id && typeof form.course_id === 'object') {
                            form.course_id = form.course_id.value;
                        }
                        form.name = `${form.surname} ${form.name} ${form.secondName}`;
                        form.external_id = this.eduCourses.find(c => c?.value === form.course_id)?.externalId || null;
                        form.profile_id = this.$store.state.user?.educont_info?.ID || null;

                        const { success, error: error2 } = await this.$store.dispatch('support/createTicket', form);
                        
                        if (success) {
                            this.isSuccess = true;
                            this.onSubmitSuccess();
                        } else { 
                            if (typeof error2 === 'object' && error2.response?.status === 500) {
                                this.showError('На сервере произошла ошибка. Обратитесь к администратору.');
                            } else {
                                this.showError(error2?.message || error2);
                            }
                        }
                    } catch {
                        this.formReset();
                        this.showError('Неизвестная ошибка. Попробуйте еще раз.');
                    }
                } else {
                    this.showError(this.getErrorsList(this.fieldNames), 'local');
                }

            this.loading = false;
        },
        onSubmitSuccess () {
            this.fieldsVisible = false;
            this.$emit('success')
        },
        // type - 'server' || 'local'
        showError(message, type = 'server') {

            if (typeof message === 'object' && !Array.isArray(message)) {
                const _errors = [];
                Object.keys(message).map(key => {
                    if (typeof message[key] === 'string') {
                        _errors.push(message[key]);
                    } else if (Array.isArray(message[key])) {
                        message[key].map(_message => {
                            _errors.push(_message);
                        })
                        message = _errors;
                    } else {
                        console.error('Can\'t recognize type of errors variable');
                    }
                })
            }

            this[`${type}Errors`] = message;
            setTimeout(() => {
                // Скроллим окно к сообщению
                window.document.querySelector('html').scrollTop = this.$refs.errorMessages.offsetTop
            })
        },
        formReset (hardReset = true) {
            if (hardReset) {
                this.isSuccess = false;
                this.fieldsVisible = true;
                this.serverErrors = null;
                this.localErrors = null;
                this.$v?.$reset?.();
            }
            this.form = {
                name: '',
                secondName: '',
                surname: '',
                email: '',
                message: '',
                category_id: null,
                course_id: null,
                file: null
            }
        },
        reloadForm () {
            this.formReset();
            this.autoCompleteFromState()
        },
        showSystemFilePicker () {
            if (this.form.file) { return false; }
            this.$refs.fileInput?.$el?.querySelector?.('button')?.click();
        },
        removeFile () {
            this.$refs.fileInput?.reset();
            this.form.file = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.text-grey {
    color: $color-grey;
}
.text-blue {
    color: $color-blue;
}
.text-red {
    color: $color-error;
}

.checkbox {
    width: 42px;
    height: 42px;
    border: 2px solid $color-blue;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: $color-blue;
    cursor: pointer;
    
    &.active {
        color: white;
        background-color: $color-blue;
    }
}
.custom-checkbox {
    cursor: pointer;
    width: 46%;
    display: inline-flex;
    padding: 0 0 0 34px;
    align-items: center;
    position: relative;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-right: 4%;

    .ok-icon {
        position: absolute;
        left: 3px;
        top: 5px;
        display: none;
    }

    @media (max-width: 600px) {
        width: 100%;
    }

    &:before {
        content: "";
        width: 24px;
        height: 24px;
        border: 2px solid $color-grey;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 0;
    }

    &.active {
        color: $color-blue;
        // font-weight: 700;
        &::before {
            border-color: $color-blue;
            background-color: $color-blue;
        }

        .ok-icon {
            display: block;
        }
    }
}
.special-selection__wrapper {
    // width: 91%;
    // margin: auto;

    // @media (max-width: 996px) {
    //     width: 97%;
    // }

    .special-selection__content {
        border: 2px solid transparent;
        padding: 0 20px;
        border-top: 0;
        border-radius: 0 0 8px 8px;
        margin-top: -41px;

        &.expanded {
            border-color: #e8e8e8;
        }
    }

}

.special-selection {
    margin: auto;
    font-size: 16px;
}
</style>