// Error mixin
const vuelidateErrorMixin = {
    data() {
        return {
            serverErrors: null
        };
    },
    computed: {
        hasAnyErrors() {
            return this.$v.$invalid || !_.isNil(this.serverErrors);
        },
        summaryError() {
          return _.get(this, 'serverErrors.summary', null);  
        }
    },
    methods: {
        getErrors(field, custom_validators) {
            const errors = []
            if (!_.get(this, `$v.${field}.$dirty`, false)) return errors
            // Required
            if(_.get(this, `$v.${field}.required`, null) === false)
                errors.push('Обязательно для заполнения');
            // minLength
            if(_.get(this, `$v.${field}.minLength`, null) === false)
            {
                const minValue = _.get(this, `$v.${field}.$params.minLength.min`, '');
                errors.push(`Не менее ${minValue} символов`);
            }
            // Email
            if(_.get(this, `$v.${field}.email`, null) === false)
                errors.push('Неверный e-mail')

            // Custom
            if(!_.isNil(custom_validators))
            {
                _.forOwn(custom_validators, (value, key) => {
                    if(_.get(this, `$v.${field}.${key}`, null) === false)
                        errors.push(value);
                });
            }

            // Server error
            const server_error = _.get(this, `serverErrors.${_.last(_.toPath(field))}`, null);
            if(!_.isNil(server_error))
                errors.push(_.isArray(server_error) ? _.get(server_error, '0', '') : server_error);

            return errors
        },
        getErrorsList (fieldNames = [], formKey = 'form') {
            if (typeof this[formKey] !== 'object') { throw new Error('Given argument must represent the string key to real object with form data.'); }
            const errors = [];
            Object.keys(this[formKey]).map(key => {
                this.getErrors(`${formKey}.${key}`).map(error => {
                    if (error) {
                        const prefix = fieldNames[key] ? 'Поле ' + fieldNames[key] + ': ' : '';
                        errors.push(prefix + error);
                    }
                })
            });
            return errors;
        },
        validate() {
            this.$v.$touch();
            return !this.$v.$invalid;
        }
    }
}

export { vuelidateErrorMixin }
