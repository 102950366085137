<template>
    <v-form class="custom-form" @submit.prevent.stop="login">
        <v-card elevation="2">
            <v-card-title class="fw-700 d-flex flex-row justify-space-between align-center blue-gradient-4">
                <span>Вход пользователя</span>
            </v-card-title>
            <!-- Body -->
            <v-card-text>
                <v-text-field
                    v-model="$v.form.login.$model"
                    :error-messages="getErrors('form.login')"
                    name="login" 
                    type="text" 
                    placeholder="Логин"
                    color="#2D9CDB"
                    flat
                    autocomplete="new-password"
                    autofocus
                ></v-text-field>

                <v-text-field 
                    v-model="$v.form.password.$model"
                    :error-messages="[
                        ...getErrors('form.password'),
                        ...(!_.isNil(summaryError) ? [summaryError] : [])
                    ]"
                    name="password" 
                    :type="passwordView" 
                    placeholder="Пароль" 
                    color="#2D9CDB"
                    flat
                    append-icon="mdi-eye"
                    autocomplete="new-password"
                    @click:append="togglePasswordView"
                ></v-text-field>
            </v-card-text>

            <v-card-text v-if="serverErrors">
                <div class="server-error mb-0">
                    <span class="ml-3">{{ serverErrors }}</span>
                    <img class="mr-2" src="@/assets/img/icon-error.svg">
                </div>
           </v-card-text>
            <!-- Footer -->
            <v-card-actions class="d-flex justify-center">
                <v-btn 
                    color="white" 
                    :elevation="0"
                    :loading="loading" 
                    type="submit"
                    class="btn__blue"
                    @click.prevent.stop="login"
                >
                    Войти
                </v-btn>
            </v-card-actions>
            <!-- <span class="d-flex justify-center my-4">или</span>
            <v-card-actions class="d-flex justify-center">
                <external-login-btn
                    redirect-url="/start"
                    @error="onExternalLoginError"
                />
            </v-card-actions> -->

            <v-alert v-if="externalLoginError" type="error">
                {{ externalLoginError }}
            </v-alert>
        </v-card>
    </v-form>
</template>
<script>
import { vuelidateErrorMixin } from '@/helpers/VuelidateHelpers'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import FormMixin from '@/mixins/FormMixin'
// import ExternalLoginBtn from '@/components/other/ExternalLoginBtn.vue'

export default {
    name: 'LoginForm',
    // components: { ExternalLoginBtn },
    mixins: [ validationMixin, vuelidateErrorMixin, FormMixin ],
    data () {
        return {
            form: {
                login: '',
                password: ''
            },
            passwordView: 'password',
            externalLoginError: null
        };
    },
    validations: {
        form: {
            login: { required },
            password: { required },
        }
    },
    methods: {
        onExternalLoginError (err) {
            this.externalLoginError = err
        },
        togglePasswordView() {
            this.passwordView = this.passwordView === 'password' ? 'text' : 'password';
        },
        async login() {
            this.loading = true;
            this.serverErrors = null;

            if (this.validate()) {
                const { success, data, error } = await this.$store.dispatch('user/login', this.form);

                if (success) {
                    await this.$store.dispatch('user/setSession', {
                        access_token: data.access_token,
                        profile: data.profile
                    });
                    this.$emit('success', 1);
                }
                else {
                    console.error(error);
                    this.serverErrors = (typeof error === 'object' && error.summary) ?
                                        error.summary :
                                        'На сервере произошла ошибка. Обратитесь к администратору.';
                }
            }

            this.loading = false;
            return;
        }
    }
}
</script>