<template>
    <div class="container">
        <v-row>
            <v-col cols="12" sm="6">
                <v-row
                    no-gutters
                    align="center"
                    class="flex-nowrap"
                >
                    <v-col class="flex-grow-0 d-flex align-center">
                        <img
                            src="@/assets/img/em-logo-1.svg"
                            class="logo"
                        />
                    </v-col>
                    <v-col class="text-no-wrap fw-400 blue-color d-flex flex-column ml-sm-10 ml-2">
                        <span class="text-big">Образовательная платформа</span>
                        <span class="fw-700 text-extra-big">Экзамен-Медиа</span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex align-center justify-sm-end">
                <div
                    class="btn__login"
                    @click="setForm(showFormName === LOGIN_FORM ? null : LOGIN_FORM)"
                >
                    <img src="@/assets/img/icon-profile.svg">
                </div>
            </v-col>
        </v-row>
        <v-row class="main-block top-offset__big">
            <v-col cols="12" md="6">
                <div class="content-block blue-gradient-2 rounded-lg d-flex flex-column mb-4">
                    <span>Библиотека цифровых образовательных ресурсов для всех уровней образования.</span>
                    
                    <span class="small mt-8">
                        Созданы по требованиям Федеральных государственных образовательных стандартов.
                    </span>
                    <span class="small mt-5">
                        Могут быть использованы с любыми учебниками как во фронтальной так и в индивидуальной работе.
                    </span>

                    <span class="mt-10 mb-10">Более 80 комплектов образовательных ресурсов</span>

                    <a
                        href="http://examen-media.ru"
                        class="rounded-lg btn__white mt-7 mb-4 text-center"
                    >Подключить бесплатный доступ</a>
                </div>
                <div class="content-block blue-gradient-1 rounded-lg d-flex flex-column mt-10">
                    <span>Мы всегда готовы ответить на ваши вопросы</span>
                    <a
                        class="pt-4 mb-7 mt-3"
                        href="mailto:platform@examen-media.ru"
                    >platform@examen-media.ru</a>
                    <div class="mt-7 mb-4 d-flex">
                        <a
                            href="javascript:void(0)"
                            class="rounded-lg btn__white"
                            @click="setForm(showFormName === FEEDBACK_FORM ? null : FEEDBACK_FORM)"
                        >Обратиться</a>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex jusfity-center justify-sm-end">
                <login-form 
                    v-if="showFormName === LOGIN_FORM"
                    ref="loginForm"
                    class="content-block pa-0"
                    @success="onLoginSuccess"
                />
                <feedback-form
                    v-else-if="showFormName === FEEDBACK_FORM"
                    class="content-block pa-0"
                    @close="onFeedbackFormClose"
                    @success="onFeedbackFormSuccess"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div class="footer blue-gradient-3 rounded-lg d-flex flex-column mt-10">
                    <v-row class="flex-column flex-sm-row">
                        <v-col cols="12" sm="2" class="d-flex align-center justify-center">
                            <img src="@/assets/img/rounded-logo.svg" class="footer-logo">
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-row class="ml-sm-4">
                                <v-col class="text-no-wrap fw-400 d-flex flex-column">
                                    <span class="line-height-40 text-center text-sm-left">Образовательная платформа</span>
                                    <span class="line-height-40 text-center text-sm-left">«Экзамен-Медиа»</span>
                                    <span class="small mt-4 text-center text-sm-left">107078, г. Москва, ул. Новая Басманная, д. 18, стр. 5</span>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5 ml-sm-4 flex-column flex-md-row">
                                <v-col cols="12" md="3" class="d-flex flex-column align-center align-sm-start">
                                    <a href="tel:+74956410039" class="fs-18">+7 (495) 641-00-39</a>
                                </v-col>
                                <v-col cols="12" md="3" class="d-flex justify-center justify-sm-start">
                                    <div class="d-flex flex-column align-center w-fit">
                                        <a href="tel:+74956410037" class="fs-18">+7 (495) 641-00-37</a>
                                        <span class="x-small">техподдержка</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" class="d-flex flex-column align-center align-sm-start">
                                    <a href="mailto:info@examen-media.ru" class="fs-18 text-center text-sm-left">info@examen-media.ru</a>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
                <p class="blue-color mt-6">
                    © ООО «Экзамен-Медиа» 2011 - {{new Date().getFullYear()}}. Все права защищены.<br>
                    Создано на базе продукта «Библиотека электронных образовательных ресурсов».<br>
                    Свидетельство о государственной регистрации программ для ЭВМ № 20226112874.<br>
                    Регистрационный № 13941 в Едином реестре российских программ для электронных вычислительных машин и баз данных.
                </p>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import LoginForm from '@/components/auth/LoginForm.vue'
import FeedbackForm from '@/components/other/FeedbackForm.vue'

export default {
    name: 'Home',
    components: { LoginForm, FeedbackForm },
    props: {
        login: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        LOGIN_FORM: () => 'LOGIN_FORM',
        FEEDBACK_FORM: () => 'FEEDBACK_FORM',
    },
    data () {
        return {
            showFormName: null,
            currentYear: (new Date().getFullYear()),
        }
    },
    mounted () {
        this.setForm(this.LOGIN_FORM);
    },
    methods: {
        setForm (formName) {
            if (![this.LOGIN_FORM, this.FEEDBACK_FORM].includes(formName)) {
                formName = null;
            }
            this.showFormName = formName;
            window.scrollTo(0, 0);
        },
        async onLoginSuccess () {
            // Go to start page
            const target = this.$store.getters['user/isSchool'] ? '/start': '/';
            return this.$router.push(target).catch(() => {});
        },
        onFeedbackFormClose () {
            this.setForm(null)
        },
        onFeedbackFormSuccess () {
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="scss" scoped>
    .container {
        max-width: 1510px;
        padding-top: 108px;
        width: 100%;

        @media (max-width: 1600px) {
            max-width: 1200px;
        }
        @media (max-width: 1300px) {
            max-width: 1050px;
            width: 98%;
        }
    }
    .main-block {
        @media (max-width: 960px) {
            flex-direction: column-reverse;
        }
    }
    .footer {
        width: 100%;
        color: white;
        padding: 30px 65px;

        @media (max-width: 1200px) {
            padding: 30px 30px;
        }
    }
    .footer, .content-block {
        width: 100%;
        color: white;
        padding: 30px 65px;

        @media (max-width: 1200px) {
            padding: 30px 30px;
        }

        @media (max-width: 550px) {
            padding: 20px;
        }

        .line-height-40 {
            line-height: 40px;
        }

        span {
            font-size: 28px;
            line-height: 28px;
            font-weight: 700;

            @media (max-width: 1200px) {
                font-size: 24px;
            }
        }
        span.small {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;

            @media (max-width: 1200px) {
                font-size: 12px;
            }
        }
        span.x-small {
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            
            @media (max-width: 1200px) {
                font-size: 10px;
            }
        }

        a {
            color: white;
            text-decoration: none;
        }
    }
    .content-block {
        max-width: 650px;
        
        @media (max-width: 960px) {
            margin: auto;
        }
    }
    .footer {
        font-weight: bold;
        span {
            white-space: break-spaces;
        }
        span.small, span.x-small {
            font-weight: bold;
        }
        .fs-18 {
            font-size: 18px;
        }
        img {
            width: 100%;
        }
    }
    .footer-logo {
        max-width: 155px;
        max-height: 155px;
        min-width: 120px;
        min-height: 120px;
        width: 100%;
    }

    .blue-color {
        color: $color-blue;
    }
    .white-color {
        color: $color-white;
    }
    .logo {
        max-width: 157px;
        max-height: 157px;
        width: 10vw;
        min-width: 55px;
        min-height: 55px;
    }
    .text-big {
        font-size: 36px;
        line-height: 50px;
        @media (max-width: 1300px) {
            font-size: 24px;
            line-height: 30px;
        }
        @media (max-width: 550px) {
            font-size: 4vw;
            line-height: initial;
        }
    }
    .text-extra-big {
        font-size: 60px;
        line-height: 50px;
        @media (max-width: 1300px) {
            font-size: 40px;
            line-height: 30px;
        }
        @media (max-width: 550px) {
            font-size: 7vw;
            line-height: initial;
        }
    }
    .btn__login {
        max-width: 150px;
        max-height: 150px;
        width: 10vw;
        height: 10vw;
        min-width: 55px;
        min-height: 55px;
        border: 2px solid #2D9CDB;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        &:active {
            opacity: .8;
        }
    }
    .btn__white {
        border: 1px solid white;
        font-size: 18px;
        font-weight: 700;
        padding: 10px 60px;
    }
    .top-offset__big {
        margin-top: 120px;
        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }
    .w-fit {
        width: fit-content;
    }
</style>

<style lang="scss">
.page-main.unauthorized,
.page-main.educont-user
{
    background-image: url(/assets/img/background-wave2.svg), url(/assets/img/background-wave1.svg) !important;
    background-position: 0% 100%, 0% 100%!important;
    background-repeat: no-repeat!important;
    background-size: 100%!important;
}
</style>