<script>
export default {
  data () {
    return {
      loading: false
    }
  }
}
</script>
<style lang="scss">
.custom-form{
    flex: 0 0 90%;


    div[role="combobox"] {
      & .v-input__append-inner + .v-input__append-inner {
        display: none;
      }
    }

    .v-icon {
      color: rgb(204, 204, 204);
    }

    .v-card{
        border-radius: 8px;
        padding-bottom: 20px;

        .v-card__title{
            color: #FFF;
            padding: 18px 25px;
            font-size: 20px;
            border-radius: 8px 8px 0 0;
            word-break: normal;
        }

        .v-card__text,
        .v-card__actions{
            padding: 5px 25px;
            button {
                text-transform: none;
                letter-spacing: normal;
                font-weight: bold;
            }
        }
        .v-card__actions{
            .v-btn{
                flex: 0 0 40%;
                @media (max-width: 400px) {
                  flex: 0 0 100%;
                }
            }
        }
    }

    .hr {
      width: 100%;
      height: 2px;
      background-color: #2D9CDB;
    }

    .btn__blue {
        background-color: $color-blue!important;
        color: white;
        height: 46px!important;
        border-radius: 10px;
    }
    .btn__white {
        background-color: white;
        color: $color-blue;
        border: 2px solid $color-blue!important;
        border-radius: 10px;
        height: 46px!important;
    }
    .server-error {
        color: $color-error;
        border: 1px solid $color-error;
        border-radius: 10px;
        padding: 5px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            width: 24px;
        }
    }
    .successMessage {
      background: $color-blue;
      border: 2px solid $color-blue;
      border-radius: 10px;
      font-size: 16px;
      color: white;
    }
    .mdi-close-circle::before {
      content: "\F0159";
      font-size: 18px;
    }

    .mdi-chevron-down::before {
      content: "\F0140";
      color: $color-blue;
      font-weight: bold;
      font-size: 30px;
    }

    .v-input input::placeholder,
    .v-textarea.v-text-field--enclosed.v-text-field--single-line:not(.v-input--dense) .v-label,
    .v-text-field .v-label
    {
      color: #0B3A55;
    }

    .v-text-field>.v-input__control>.v-input__slot:before,
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset
    {
      border-color: #F2F2F2;
      border-width: 1px;
      border-radius: 10px;
    }
    .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset
    {
      border-width: 2px;
    }

    .v-expansion-panel-content__wrap {
      margin: -10px 4.5% 24px;
      padding: 16px 17px 10px;
      border: 2px solid #f2f2f2;
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .expansion-panel__subtitle {
      color: #2D9CDB;
    }

    .new-file-zone {
      width: 150px;
      height: 150px;
      border: 1px solid #CCCCCC;
      color: $color-blue;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      text-align: center;
      overflow: hidden;
      position: relative;

      &.not-empty {
        cursor: default;
      }

      .clear-icon {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
}
.v-application.unauthorized {
  .v-list-item.v-list-item--link {
    .v-list-item__content {
      z-index: 1;
    }
    &:before {
      transition: none !important;
    }
    &:hover {
      .v-list-item__content {
        color: $color-blue;
      }
      &:before {
        background-color: #E3F7FE !important;
        opacity: 1 !important;
      }
    }
  }
  .v-list-item--active:focus, .v-list-item.v-list-item--highlighted {
    &:before {
      opacity: 1!important;
      background-color: #E3F7FE !important;
    }
    .v-list-item__content {
      font-weight: bold;
    }
  }
}
</style>